<template>
	<b-modal hide-footer hide-header centered id="collection-edit">
		<div class="h2 mb-4">
			Edit Collection
		</div>
		<ValidationObserver v-slot="{ handleSubmit, reset, pristine, failed }">
			<b-form @submit.prevent="handleSubmit(save)" @reset.prevent="reset">
				<b-form-group class="text-left">
					<ValidationProvider v-slot="v" name="Collection name" rules="required|min:1">
						<b-form-input type="text" maxlength="40" v-model="item.name" :state="getValidationState(v)"  />
						<b-form-invalid-feedback class="mt-2" id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
					</ValidationProvider>
				</b-form-group>
				<div class="d-flex w-100 justify-content-between align-items-center">
					<div class="mr-3 d-flex justify-content-center align-items-center">
						<b-button variant="primary" type="submit" size="sm" :disabled="pristine || failed">
							Save
						</b-button>
						<b-button class="ml-2" size="sm" variant="outline-dark" @click.prevent="cancel()">
							Cancel
						</b-button>
					</div>
					<div>
						<b-button class="ml-2" size="sm" variant="outline-dark" @click.prevent="remove()">
							Delete Collection
						</b-button>
					</div>
				</div>
			</b-form>
		</ValidationObserver>
	</b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from '@/modules/vendor/validate';
export default {
    name: 'CollectionEditModal',
    props: ['item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            confirmModal: false
        };
    },
    methods: {
        save() {
            this.item.save();
            this.$bvModal.hide('collection-edit');
        },
        cancel() {
            this.item.reset();
            this.$bvModal.hide('collection-edit');
        },
        remove() {
            this.confirmModal = true;
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this collection? The stylesheets in this collection will not be deleted.', this.confirmModalOpts).then(confirm => {
                if (confirm) {
                    this.item.remove();
                    this.$router.push('/collections');
                }
            });
        }
    }
}
</script>
